import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { enableMapSet, setAutoFreeze } from "immer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import {applyMiddleware, createStore} from "redux";
import reducers from "./reducers";
import staffReducers from "./reducers/staff";

import Root from "./routes/root/Root";
import Login from "./routes/login/Login";
import Loader from "./routes/loader/Loader";
import LoginByOtp from "./routes/loginByOtp/LoginByOtp";
import Registration from "./routes/registration/Registration";
import Empty from "./routes/staff/Empty";
import AdsNotification from "./routes/ads/AdsNotification";

import MiniLoading from "./components/MiniLoading";

import apiStaffSupport from "./api/staff/support";
import apiMemberSupport from "./api/support";

import './styles/pages/_main.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const ComingSoon = lazy(() => import("./routes/comingsoon/ComingSoon"));
const Dao = lazy(() => import("./routes/dao/Dao"));
const Downloads = lazy(() => import("./routes/downloads/Downloads"));
const MyPlots = lazy(() => import("./routes/myPlots/MyPlots"));
const PlotTickets = lazy(() => import("./routes/plotTickets/PlotTickets"));
const About = lazy(() => import("./routes/about/About"));
// const MarketplaceInfo = lazy(() => import("./routes/marketplace/MarketplaceInfo"));
const GoldRush = lazy(() => import("./routes/goldrush/GoldRush"));
const Announcements = lazy(() => import("./routes/announcements/Announcements"));
const PublicAnnouncements = lazy(() => import("./routes/announcements-public/Announcements"));
const TutorialsList = lazy(() => import("./routes/tutorials/TutorialsList"));
// const AdsInfo = lazy(() => import("./routes/ads-public/Ads"));
const StaffLogin = lazy(() => import("./routes/staff/Login"));
const StaffAnnouncements = lazy(() => import("./routes/staff/Announcements"));
const StaffAnnouncementsCreateEdit = lazy(() => import("./routes/staff/AnnouncementsCreateEdit"));
const StaffAnnouncementsHistory = lazy(() => import("./routes/staff/AnnouncementsHistory"));
const StaffEvents = lazy(() => import("./routes/staff/Events"));
const StaffEventsCreateEdit = lazy(() => import("./routes/staff/EventsCreateEdit"));
const StaffManagementCreateEdit = lazy(() => import("./routes/staff/ManagementCreateEdit"));
const MemberDetail = lazy(() => import("./routes/staff/member/MemberDetail"));
const ManagementMember = lazy(() => import("./routes/staff/member/ManagementMember"));
const Management = lazy(() => import("./routes/staff/Management"));
const Purchases = lazy(() => import("./routes/staff/purchases/Purchases"));
const PurchaseDetail = lazy(() => import("./routes/staff/purchases/PurchaseDetail"));
const RefundConfirmation = lazy(() => import("./routes/staff/purchases/RefundConfirmation"));
const EventsList = lazy(() => import("./routes/events/EventsList"));
const SupportLists = lazy(() => import("./routes/staff/support/SupportList"));
const SupportListsAll = lazy(() => import("./routes/staff/support/SupportListAll"));
const SupportListsAssigned = lazy(() => import("./routes/staff/support/SupportListAssigned"));
const SupportTicketCreate = lazy(() => import("./routes/staff/support/SupportTicketCreate"));
const SupportPage = lazy(() => import("./routes/staff/support/SupportPage"));
const SupportPageHistory = lazy(() => import("./routes/staff/support/SupportPageHistory"));
const SupportStatistics = lazy(() => import("./routes/staff/statistics/SupportStatistics"));
const SalesStatistics = lazy(() => import("./routes/staff/statistics/sales/SalesStatistics"));
const PlotSales = lazy(() => import("./routes/staff/plotSales/PlotSales"));
// const GameCoins = lazy(() => import("./routes/staff/gameCoins/GameCoins"));
// const GameCenterStaff = lazy(() => import("./routes/staff/gameCenter/GameCenter"));
// const GameCenterDetailsStaff = lazy(() => import("./routes/staff/gameCenter/GameCenterDetails"));
const MemberSupportCreate = lazy(() => import("./routes/support/SupportCreate"));
const MemberSupportList = lazy(() => import("./routes/support/SupportList"));
const MemberSupportListPage = lazy(() => import("./routes/support/SupportListPage"));
const MemberSupportTicket = lazy(() => import("./routes/support/SupportTicket"));
const SupportTicketConversation = lazy(() => import("./routes/support/SupportTicketConversation"));
const MemberSupportTicketHistory = lazy(() => import("./routes/support/SupportTicketHistory"));
const AdNet = lazy(() => import("./routes/staff/adnet/AdNet"));
const AdNetDetail = lazy(() => import("./routes/staff/adnet/AdNetDetail"));
const AdNetModeration = lazy(() => import("./routes/staff/adnet/AdNetModeration"));
const AdNetDetailModeration = lazy(() => import("./routes/staff/adnet/AdNetDetailModeration"));
const Tutorials = lazy(() => import("./routes/staff/Tutorials"));
const TutorialsCreateEdit = lazy(() => import("./routes/staff/TutorialsCreateEdit"));
const Marketplace = lazy(() => import("./routes/marketplace/MarketplaceRoot"));
const MarketplaceBuildingsExchangeHistory = lazy(() => import("./routes/marketplace/exchange/ExchangeUpgradeHistory"));
const BuldingPurchaseHistory = lazy(() => import("./routes/marketplace/exchange/BuldingPurchaseHistory"));
const MarketplaceBuildingsCongratulations = lazy(() => import("./routes/marketplace/buildings/MarketplaceBuildingsCongratulations"));
const LumberYard = lazy(() => import("./routes/lumberyard/LumberYard"));
const GameCenterMain = lazy(() => import("./routes/gameCenter/GameCenterMain"));
const AssetsHistory = lazy(() => import("./routes/assetsHistory/AssetsHistory"));
const StaffAssetsHistory = lazy(() => import("./routes/staff/assetsHistory/AssetsHistory"));
const PurchaseHistory = lazy(() => import("./routes/purchaseHistory/PurchaseHistory"));
const Ads = lazy(() => import("./routes/ads/Ads"));
const AdsArchive = lazy(() => import("./routes/ads/AdsArchive"));
const CreditsHistory = lazy(() => import('./routes/ads/credits-history/CreditsHistory'));
const AdOverview = lazy(() => import('./routes/ads/AdOverview'));
const AdsSchedule = lazy(() => import('./routes/ads/ads-schedule/AdsSchedule'));
const PointsFoundersPlan = lazy(() => import("./routes/points/PointsFoundersPlan"));
const CitizenProgram = lazy(() => import("./routes/points/CitizenProgram"));
const PointsRevenueSharingProgram = lazy(() => import("./routes/points/PointsRevenueSharingProgram"));
const PointsRevenueSharingProgramDetails = lazy(() => import("./routes/points/Details"));
const InterWorldLandingHome = lazy(() => import("./routes/root/landingInterWorld/Home"));
const ProductRegistration = lazy(() => import("./routes/staff/productRegistration/ProductRegistration"));
const NFTOfficeTransferHistory = lazy(() => import("./routes/staff/NFTOfficeTransferHistory"));
const StaffManagementPlotTicket = lazy(() => import("./routes/staff/plotTicket/ManagementPlotTicket"));

enableMapSet();
setAutoFreeze(false);

const middleware = [
    // logger,
];
const store = createStore(reducers, applyMiddleware(...middleware));
const staffStore = createStore(staffReducers, applyMiddleware(...middleware));
const rootElement = document.getElementById("root");

const defaultFallback = <MiniLoading />;

const loadWrapper = (component, fallback = defaultFallback) => {
  return <Suspense fallback={fallback}>
    {component}
  </Suspense>
}

ReactDOM.render(
  <React.StrictMode>
      <Provider store={window.location.pathname.includes("staff") ? staffStore : store}>
          <BrowserRouter>
              <Routes>
                  <Route path="/:lang/interworld/home" element={loadWrapper(<InterWorldLandingHome />)} />

                  <Route path="/staff" element={<Empty/>} />
                  <Route path="/staff/announcements" element={loadWrapper(<StaffAnnouncements />)} />
                  <Route path="/staff/login" element={loadWrapper(<StaffLogin />)} />
                  <Route path="/staff/announcements/create" element={loadWrapper(<StaffAnnouncementsCreateEdit />)} />
                  <Route path="/staff/announcements/history/:id" element={loadWrapper(<StaffAnnouncementsHistory />)} />
                  <Route path="/staff/announcements/:id" element={loadWrapper(<StaffAnnouncementsCreateEdit />)} />
                  <Route path="/staff/events" element={loadWrapper(<StaffEvents />)} />
                  <Route path="/staff/events/create" element={loadWrapper(<StaffEventsCreateEdit />)} />
                  <Route path="/staff/events/:id" element={loadWrapper(<StaffEventsCreateEdit />)} />
                  <Route path="/staff/management" element={loadWrapper(<Management />)} />
                  <Route path="/staff/management/create" element={loadWrapper(<StaffManagementCreateEdit />)} />
                  <Route path="/staff/profile" element={loadWrapper(<StaffManagementCreateEdit />)} />
                  <Route path="/staff/member" element={loadWrapper(<ManagementMember />)} />
                  <Route path="/staff/member/:id" element={loadWrapper(<MemberDetail />)} />
                  <Route path="/staff/tutorials" element={loadWrapper(<Tutorials />)} />
                  <Route path="/staff/tutorials/create" element={loadWrapper(<TutorialsCreateEdit />)} />
                  <Route path="/staff/tutorials/:id" element={loadWrapper(<TutorialsCreateEdit />)} />
                  <Route path="/staff/purchases" element={loadWrapper(<Purchases />)} />
                  <Route path="/staff/purchases/:id" element={loadWrapper(<PurchaseDetail />)} />
                  <Route path="/staff/purchases/:id/refund" element={loadWrapper(<RefundConfirmation />)} />
                  <Route path="/staff/support/" element={loadWrapper(<SupportLists />)} >
                    <Route path="all" element={loadWrapper(<SupportListsAll type='all' />)} />
                    <Route path="assigned" element={loadWrapper(<SupportListsAssigned />)} />
                    <Route path="unassigned" element={loadWrapper(<SupportListsAll type='unassigned' />)} />
                    <Route index element={<Navigate to={"/staff/support/assigned"} replace />}/>
                    <Route path="*" element={<Navigate to={"/staff/support/assigned"} replace />} />
                  </Route>
                  <Route path="/staff/statistics/adnet" element={loadWrapper(<AdNet />)} />
                  <Route path="/staff/statistics/adnet/:id" element={loadWrapper(<AdNetDetail />)} />

                  <Route path="/staff/ad-moderation" element={loadWrapper(<AdNetModeration />)} />
                  <Route path="/staff/ad-moderation/:id" element={loadWrapper(<AdNetDetailModeration />)} />
                  <Route path="/staff/product-registration" element={loadWrapper(<ProductRegistration />)} />

                  <Route path="/staff/statistics/assets-history" element={loadWrapper(<StaffAssetsHistory />)} />
                  <Route path="/staff/statistics/support" element={loadWrapper(<SupportStatistics />)} />
                  <Route path="/staff/statistics/sales" element={loadWrapper(<SalesStatistics />)} />
                  <Route path="/staff/statistics/plot-sales" element={loadWrapper(<PlotSales />)} />
                  {/* <Route path="/staff/statistics/game-center" element={loadWrapper(<GameCenterStaff />)} />
                  <Route path="/staff/statistics/game-center/:memberId" element={loadWrapper(<GameCenterDetailsStaff />)} />
                  <Route path="/staff/statistics/game-coins" element={loadWrapper(<GameCoins />)} /> */}
                  <Route path="/staff/support/ticket/:id" element={loadWrapper(<SupportPage />)} >
                    <Route path="conversation" element={loadWrapper(<SupportTicketConversation apiSupport={apiStaffSupport} />)} />
                    <Route path="history" element={loadWrapper(<SupportPageHistory />)} />
                    <Route index element={<Navigate to={"conversation"} replace />}/>
                    <Route path="*" element={<Navigate to={"conversation"} replace />} />
                  </Route>
                  <Route path="/staff/support/create" element={loadWrapper(<SupportTicketCreate />)} />
                  <Route path="/staff/statistics/nft-office-transfer-history" element={loadWrapper(<NFTOfficeTransferHistory />)} />
                  <Route path="/staff/plot-ticket" element={<Navigate to={"/staff/experience-ticket"} replace />} />
                  <Route path="/staff/experience-ticket" element={loadWrapper(<StaffManagementPlotTicket />)} />

                  <Route path="/staff/*" element={<Empty/>} />

                  <Route path="/:lang" element={<Root key={"main"} />}/>
                  <Route path="/:lang/comingsoon" element={loadWrapper(<ComingSoon />)} />
                  {process.env.REACT_APP_DAO_LINK === 'enabled' && <Route path="/:lang/dao" element={loadWrapper(<Dao />)} />}
                  <Route path="/:lang/downloads" element={loadWrapper(<Downloads />)} />
                  <Route path="/:lang/my-plots" element={loadWrapper(<MyPlots />)} />
                  <Route path="/:lang/plot-tickets" element={loadWrapper(<PlotTickets />)} />

                  <Route path="/:lang/experience-tickets" element={loadWrapper(<PlotTickets params={{title:"VLANDS", filter:'VTICKET', showBack: {title: 'AW-968.GOTO_OLD_HISTORY', url: '/:lang/experience-tickets-old'}}} />)} />
                  <Route path="/:lang/experience-tickets/:plotId" element={loadWrapper(<PlotTickets params={{title:"", filter: null}} />)} />

                  <Route path="/:lang/experience-tickets-old" element={loadWrapper(<PlotTickets params={{title:"", filter:'TICKET', showBack: {title: 'AW-968.GOTO_BACK', url: '/:lang/experience-tickets'}}} />)} />
                  <Route path="/:lang/experience-tickets-old/:plotId" element={loadWrapper(<PlotTickets params={{title:"", filter: null}} />)} />
                  {
                      // AW-937
                      false && (<>
                          <Route path="/:lang/gold-rush" element={loadWrapper(<GoldRush/>)} />
                          <Route path="/:lang/lumber-yard" element={loadWrapper(<LumberYard/>)} />
                      </>)
                  }
                  <Route path="/:lang/wow" element={loadWrapper(<ComingSoon/>)} />
                  <Route path="/:lang/about" element={loadWrapper(<About />)} />
                  <Route path="/:lang/login" element={<Login />} />
                  <Route path="/:lang/registration" element={<Registration />} />
                  <Route path="/:lang/loader" element={<Loader />} />
                  {/*<Route path="/:lang/marketplace-info" element={loadWrapper(<MarketplaceInfo />)} />*/}
                  <Route path="/:lang/marketplace" element={loadWrapper(<Marketplace />)} />
                  <Route path="/:lang/marketplace/successful" element={loadWrapper(<MarketplaceBuildingsCongratulations />)} />
                  <Route path="/:lang/marketplace/failed" element={loadWrapper(<MarketplaceBuildingsCongratulations />)} />
                  <Route path="/:lang/marketplace/place/:buildingID" element={<Root key={"buildingPlace"} buildPlacing={true} />} />
                  <Route path="/:lang/marketplace/exchangeupgradehistory" element={loadWrapper(<MarketplaceBuildingsExchangeHistory  />)} />
                  <Route path="/:lang/marketplace/bulding-purchase-history" element={loadWrapper(<BuldingPurchaseHistory  />)} />
                  <Route path="login-by-otp" element={<LoginByOtp />} />
                  <Route path='/:lang/announcements' element={loadWrapper(<Announcements />)} />
                  <Route path='/:lang/public-announcements' element={loadWrapper(<PublicAnnouncements />)} />
                  <Route path='/:lang/events' element={loadWrapper(<EventsList />)} />
                  <Route path='/:lang/events/:id' element={loadWrapper(<EventsList />)} />
                  <Route path="/:lang/assets-history" element={loadWrapper(<AssetsHistory />)} />
                  <Route path="/:lang/purchase-history" element={loadWrapper(<PurchaseHistory />)} />
                  <Route path='/:lang/tutorials' element={loadWrapper(<TutorialsList />)} />

                  <Route path='/:lang/support' element={loadWrapper(<MemberSupportList />)} >
                    <Route path="all" element={loadWrapper(<MemberSupportListPage filter='all'/>)} />
                    <Route path="open" element={loadWrapper(<MemberSupportListPage filter='open'/>)} />
                    <Route path="in-progress" element={loadWrapper(<MemberSupportListPage filter='in-progress'/>)} />
                    <Route path="closed" element={loadWrapper(<MemberSupportListPage filter='closed'/>)} />
                    <Route index element={<Navigate to={"all"} replace />}/>
                  </Route>
                  <Route path='/:lang/support/create' element={loadWrapper(<MemberSupportCreate />)} />
                  <Route path='/:lang/support/:id' element={loadWrapper(<MemberSupportTicket />)} >
                    <Route path="conversation" element={loadWrapper(<SupportTicketConversation apiSupport={apiMemberSupport} />)} />
                    <Route path="history" element={loadWrapper(<MemberSupportTicketHistory />)} />
                    <Route index element={<Navigate to={"conversation"} replace />}/>
                    <Route path="*" element={<Navigate to={"conversation"} replace />} />
                  </Route>
                  {
                      //AW-937
                      false && (<>
                          <Route path="/:lang/game-center/" element={loadWrapper(<GameCenterMain />)} />
                          <Route path="/:lang/game-center/:type" element={loadWrapper(<GameCenterMain />)} />
                      </>)
                  }

                  {/*<Route path="/:lang/partners" element={loadWrapper(<AdsInfo />)} />*/}

                  <Route path="/:lang/ads-overview" element={loadWrapper(<AdOverview />)} />
                  <Route path="/:lang/ads-manager" element={loadWrapper(<Ads />)} />
                  <Route path="/:lang/ads-archive" element={loadWrapper(<AdsArchive />)} />
                  <Route path='/:lang/ads-archive/:id' element={loadWrapper(<AdsArchive />)} />
                  <Route path="/:lang/ads-credit-history" element={loadWrapper(<CreditsHistory />)} />

                  <Route path='/:lang/ads-manager/successful' element={<AdsNotification />} /> {/* may not be used */}
                  <Route path='/:lang/ads-manager/:id' element={loadWrapper(<Ads />)} />

                  <Route path='/:lang/ads-schedule/:id' element={loadWrapper(<AdsSchedule />)} />
                  <Route path='/:lang/ads-schedule' element={loadWrapper(<AdsSchedule />)} />

                  <Route path='/:lang/points/citizen-program' element={loadWrapper(<CitizenProgram />)} />
                  <Route path='/:lang/points/founders-plan' element={loadWrapper(<PointsFoundersPlan />)} />
                  {
                      //AW-937
                      false && <>
                          <Route path='/:lang/points/revenue-sharing-program' element={loadWrapper(<PointsRevenueSharingProgram />)} />
                          <Route path='/:lang/points/revenue-sharing-program/:month' element={loadWrapper(<PointsRevenueSharingProgramDetails />)} />
                      </>
                  }

                  <Route path="*" element={<Navigate to={"/en"} />} />
              </Routes>
          </BrowserRouter>
      </Provider>
  </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
