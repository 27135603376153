import {useEffect} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {
    initMap, setDistricts,
    setMapRequestAlreadySent,
    setMapDistrictThumbnails,
    setMapBuiltBuildings
} from "../actions";

import mapService from "../api/map";
import apiBuildings from "../api/buildings";

const MapLoader = ({
    mapData,
    member,
    initMap,
    setDistricts,
    mapRequestAlreadySent,
    setMapRequestAlreadySent,
    setMapDistrictThumbnails,
    setMapBuiltBuildings,
}) => {
    const location = useLocation();
    const { lang } = useParams();

    const isMapPage = !!location.pathname.match(new RegExp(`/${lang}/*$`)) || location.pathname.includes(`/${lang}/marketplace/place/`);
    useEffect(() => {
        if (member.id && isMapPage && !mapData.size && !mapRequestAlreadySent) {
            setMapRequestAlreadySent(true);
            Promise.all([
                mapService.districts().then(response => response.json()),
                mapService.map().then(response => response.json()),
                mapService.districtThumbnails().then(response => response.json()),
                apiBuildings.buildings().then(data => data.json()),
            ]).then(([districts, mapData, thumbnails, occupiedFields]) => {
                setDistricts(districts);

                const mapState = new Map(mapData.map(el => [`${el[0]}:${el[1]}`, el]));
                initMap(mapState);

                setMapDistrictThumbnails(thumbnails);

                setMapBuiltBuildings(occupiedFields);
            })
            .finally(() => {
                setMapRequestAlreadySent(false);
            });
        }

    }, [location, member]);

    return null;
};

export default connect(
    (state) => ({
        member: state.member,
        mapData: state.map.data,
        districts: state.map.districts,
        mapRequestAlreadySent: state.map.mapRequestAlreadySent,
    }),
    (dispatch) => ({
        initMap: (data) => {
            dispatch(initMap(data))
        },
        setDistricts: (districts) => {
            dispatch(setDistricts(districts))
        },
        setMapRequestAlreadySent: (isAlreadySent) => {
            dispatch(setMapRequestAlreadySent(isAlreadySent))
        },
        setMapDistrictThumbnails: (thumbnails) => {
            dispatch(setMapDistrictThumbnails(thumbnails))
        },
        setMapBuiltBuildings: (buildings) => {
            dispatch(setMapBuiltBuildings(buildings))
        }
    }),
)(MapLoader);
